// Dependency
import React, { useRef, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import useIntersection from "../../templates/product/hooks/useIntersection"
import GA from "../../services/ga.service"

// Styles
import * as styles from "./blog-product-card.module.scss"

// Services
import UtilsService from "../../services/utils.service"
import { useCart } from "../../contexts/cart-context"

// Assets
//import atc from "../../images/atc.svg"

// Internationalization
import { useTranslation } from "react-i18next"

// Template
const BlogProductCard = ({
  title,
  image,
  slug,
  prices,
  prod,
  post,
  position,
}) => {
  const ref = useRef()

  const [viewedProducts, setViewedProducts] = useState([])

  const inViewport = useIntersection(ref.current)

  // Translation Function
  const { t } = useTranslation()

  const { addToCart } = useCart()

  if (inViewport && prod) {
    let unique = `${prod.product}blogg`
    if (!viewedProducts.includes(unique)) {
      GA.onProductView(prod, `Blog - ${post}`, position)
      setViewedProducts([...viewedProducts, unique])
    }
  }

  return (
    <Link
      to={`/${UtilsService.getLocaleFromUrl().country}/${t(
        "slug.product"
      )}/${slug}/`}
      className={`${styles.cdBlogProductCard} cd-dark-mode-black`}
      onClick={e => {
        if (!e.target.classList.contains("cd-atc"))
          GA.onProductClick(prod, `Blog - ${post}`, position)
      }}
    >
      <div ref={ref} className={styles.cdBlogProductCardLeft}>
        <div className={`${styles.cdBlogProductCardImage} cd-background-img`}>
          <Img fluid={image.fluid} alt="" />
        </div>
        <div>
          <h3>{title}</h3>
          <div className={styles.cdBlogProductCardPrice}>
            {prod && prod.priceAsNumber !== prod.priceBeforeDiscountAsNumber ? (
              <>
                <ins className="cd-product-price-value">{prod.price}</ins>
                <div className={` cd-product-price-original`}>
                  <del className="cd-product-price-value">
                    {prod.priceBeforeDiscount}
                  </del>
                </div>
              </>
            ) : (
              <span className="cd-product-price-value"> {prices}</span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.cdBlogProductCardRight}>
        <span
          className={`${styles.cdBlogProductCardAtc} cd-atc`}
          onClick={event => {
            event.preventDefault()
            addToCart(prod.items[0].item, `Blog - ${post}`, prod)
          }}
        >
          <i className="material-icons cd-atc">add_shopping_cart</i>
        </span>
      </div>
    </Link>
  )
}

export default BlogProductCard
