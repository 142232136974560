// Dependency
import React from "react"
//import Img from "gatsby-image"

// Components
import BlogPostAuthor from "./blog-post-author/blog-post-author"
//import BlogPostRelated from './blog-post-related/blog-post-related';
import BlogPostSocial from "./blog-post-social/blog-post-social"

// Services
import DateService from "../../services/date.service"

// Internationalization
import { useTranslation } from "react-i18next"

// Styles
import * as styles from "./blog-post.module.scss"
//import Layout from "../layouts"
import BlogProductCard from "../cards/blog-product-card"

// Assets
//import authorUrl from './assets/author-thumbnail.png';

// Template
const BlogPost = ({ postData, centraProducts }) => {
  // Extract blog post data
  const post = postData

  const prods = []
  postData.products.map((p, index) => {
    centraProducts.map(prod => {
      if (p.centraId.toString() === prod.centraProduct) prods[index] = prod
    })
  })

  // Translation Function
  const { t } = useTranslation()

  // Template
  return (
    <React.Fragment>
      <div className={`${styles.blogPost} cd-dark-mode-dark`}>
        <div className="cd-max-width">
          <div className={styles.cdSingleBlogTop}>
            <h1 className={`${styles.blogPost__title}`}>{post.title}</h1>
            <div className={`${styles.blogPostMeta}`}>
              <BlogPostAuthor
                imageurl={
                  post.author && post.author.featuredImage
                    ? post.author.featuredImage.fluid
                    : ""
                }
                categories={post.categories}
                author={post.author.name}
                readingtime={post.readingTime}
                date={post.date ? DateService.format(post.date) : ""}
              />
              <BlogPostSocial cssclass={`${styles.blogPostMeta__social}`} />
            </div>
          </div>
        </div>
        {/*
          <Img className={styles.cdFeaturedImg} fluid={post.featuredMedia.fluid} alt={post.title} />
*/}
        <div className="cd-max-width">
          <div className="triggerbee-blog-section"></div>
          <div
            className={styles.cdSingleBlogContent}
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <div className="triggerbee-blog-section"></div>
          {post && post.products.length !== 0 && (
            <div className={styles.cdBlogProducts}>
              <h4>{t("blog.products")}:</h4>
              {post.products.map((product, index) => {
                return (
                  <BlogProductCard
                    slug={product.slug}
                    image={product.featuredImage}
                    prod={prods[index]}
                    title={product.title}
                    key={index}
                    position={index+1}
                    post={post.title}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      {/*
      <BlogPostRelated title="Relaterat" />
*/}
    </React.Fragment>
  )
}

export default BlogPost
