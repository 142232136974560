// Dependency
import React, { useState } from "react";
import { graphql } from "gatsby";


// Components
import Layout from "../../components/layouts";
import BlogPost from '../../components/blog-post/blog-post';

import ProductList from "../../templates/product/hooks/product-list";


export const query = graphql`
  query ($slug: String!) {
    datoCmsArticle(seoSlug: { eq: $slug }) {
      title
      slug
      seoMetaTags {
        tags
      }
      featuredMedia{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      locale
      id
      content
      categories {
        id
        slug
        name
      }
      author {
        id
        name
      }
      date
      excerpt
      topStory
      featured
      readingTime
      products{
        centraId
        slug
        featuredImage{
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        locale
        title
        saleBadge
        bestseller
      }
    }
  }`;

// Template
const BlogPostPage = (props) => {

    const [productList, setProductList] = useState([]);

    return (
    <Layout seoData={props.data.datoCmsArticle.seoMetaTags} productData={props.pageContext} country={props.pageContext.country} location={props.location}>
        <ProductList createProductList={setProductList} />
        <BlogPost postData={props.data.datoCmsArticle} centraProducts={productList} />
    </Layout>
  );
};

export default BlogPostPage;