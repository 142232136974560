// Dependencies
import React from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from 'react-share';

// Assets
import iconFacebook from './../assets/icon-facebook.svg';
import iconTwitter from './../assets/icon-twitter.svg';
import iconLinkedin from './../assets/icon-linkedin.svg';

// Template
const BlogPostSocial = ({ cssclass }) => {

  /*const articleURL = () => {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }
  };*/

  let articleURL = '';
  if (typeof window === 'object') {
    articleURL = String(window.location);
  }

  return <div className={cssclass}>
    <TwitterShareButton url={articleURL}>
      <img src={iconTwitter} alt="" />
    </TwitterShareButton>
    <LinkedinShareButton url={articleURL}>
      <img src={iconLinkedin} alt="" />
    </LinkedinShareButton>
    <FacebookShareButton url={articleURL}>
      <img src={iconFacebook} alt="" />
    </FacebookShareButton>
  </div>
};

export default BlogPostSocial;