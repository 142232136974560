// extracted by mini-css-extract-plugin
export var blogPost = "blog-post-module--blogPost--44f7e";
export var blogPostMeta = "blog-post-module--blogPostMeta--62822";
export var blogPostMeta__social = "blog-post-module--blogPostMeta__social--05706";
export var blogPost__imgBig = "blog-post-module--blogPost__imgBig--9d830";
export var blogPost__imgMid = "blog-post-module--blogPost__imgMid--c4125";
export var blogPost__title = "blog-post-module--blogPost__title--2f422";
export var cdBlogProducts = "blog-post-module--cd-blog-products--ddde9";
export var cdFeaturedImg = "blog-post-module--cd-featured-img--0c467";
export var cdSingleBlogContent = "blog-post-module--cd-single-blog-content--fd037";
export var cdSingleBlogTop = "blog-post-module--cd-single-blog-top--f18bb";